import {initializeApp} from "firebase/app";
import {getFirestore} from "firebase/firestore"
import { getAuth } from "firebase/auth";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyD6NG79qzanNMUDuVI-Mm0deRmpk21ZCU8",
  authDomain: "scanrecs-12414.firebaseapp.com",
  databaseURL: "https://scanrecs-12414-default-rtdb.firebaseio.com",
  projectId: "scanrecs-12414",
  storageBucket: "scanrecs-12414.appspot.com",
  messagingSenderId: "897682699239",
  appId: "1:897682699239:web:e33675cf4f2caaf256c342"
};
const firebaseApp = initializeApp(firebaseConfig);
export const db = getFirestore(firebaseApp)
export const auth = getAuth(firebaseApp)

