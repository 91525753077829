import React  from "react"

import Home from "./Home"
import Affiliate from './pages/Affiliate';
import Contact from "./pages/Contact";
import About from "./pages/About";

import { Route, Routes } from 'react-router-dom';
import { Helmet } from "react-helmet";
import { pageColor } from "./pages/styleconstants";
import NotFound from "./pages/NotFound";
import TermsOfService from "./pages/TermsOfService";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import { ArtistDashboard } from "./pages/ArtistDashboard";

function App() {

  return (
  <div className='App' style={{ color: "black"}}>
    <Helmet>
        <style>{`body { background-color: ${pageColor}; }`}</style>
    </Helmet>
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="affiliate" element={<Affiliate />} />
      <Route path="contact" element={<Contact />} />
      <Route path="about" element={<About />} />
      <Route path="terms" element={<TermsOfService />} />
      <Route path="privacy" element={<PrivacyPolicy />} />
      {/*<Route path="products" element={<Products />} /> */} 
      
      <Route path="protected" element={<ArtistDashboard />} />

      <Route path="*" element={<NotFound />} />
      
    </Routes>
  </div>);
}

export default App;