import "./page.css";
import React from "react";
import { VideoHeader } from "../components/allPages/VideoHeader";
import { AffiliateSignup } from "../components/AffiliateSignup";
import { pageColor } from "./styleconstants";
import { PageFooter } from "../components/allPages/PageFooter";
import { NavBar } from "../components/allPages/NavBar";

function Affiliate() {
  return (
    <div className="page" style={{ backgroundColor: pageColor }}>
      <VideoHeader pageColor={pageColor} />
      <NavBar />
      <div className="headerTitle">
        <h1 className="headerText">Become a Scan Affiliate</h1>
      </div>
      <div className="contentContainer">
        <div className="contentSubcontainers">
          <div className="contentSubcontainer">
            <h3>What is an Affiliate?</h3>
            <p>
              As a Scan Affiliate you will be entitled to a predetermined share
              of revenue <b>(30% of total revenue)</b> for every Product that
              has been sold using your personal product links. <br /> <br />
              <i>
                Example: 40 US$ sale with your code -&gt; 12 US$ revenue for you
                *
              </i>
            </p>
            <br />
            <h3>Why do we offer this program?</h3>
            <p>
              We believe that in affiliate marketing <b>everyone is a winner</b>
              . Our goal is to further our reach and give more interested
              artists the opportunity to take a look at our products, while you
              and a wide range of people within our community will get your
              share of the profits.
              <br /> <br />
              Therefore, this affiliate program is the best way to support Scan
              Records, aswell as your favorite artists that are putting all of
              their effort into creating the highest quality packs they can!
            </p>
            <br />
            <p style={{ color: "rgba(255,255,255,.7)", fontSize: "15px" }}>
              <i>
                * The payout will occur periodically based on Scan Records'
                current capacity. For further information contact us at
                contact@scanrecs.com
              </i>
            </p>
          </div>
          <div className="contentSubcontainer">
            <h3>How do I become an affiliate?</h3>
            <p>
              <ul>
                <li>Sign Up with the form below</li>
                <li>Wait for us to review and process your submission</li>
                <li>
                  You will recieve an email with all needed information and your
                  presonal affiliate code
                </li>
                <li>
                  <i>
                    <b>Start marketing</b>
                  </i>
                </li>
              </ul>
            </p>
            <br />
            <h3>Can I cancel my affiliate relationship?</h3>
            <p>
              <b>Yes.</b> You can cancel your affiliate relationship with Scan
              Records at any point in time. Simply write an e-mail to
              <i> contact@scanrecs.com</i> and we will process your cancelation.
              <br />
              <br />
              At the same time, your affiliate relationship is not a binding
              contract, you can chose whenever and how much you want to promote
              your own product codes and therefore do not have to cancel your
              affiliate relationship if you chose to pause your promotion
              longterm. **
            </p>
            <br />
            <p style={{ color: "rgba(255,255,255,.7)", fontSize: "15px" }}>
              <i>
                ** We might disable any codes that have been inactive for
                extended peroids of time. If your code does indeed get
                deactivated, you can always reapply to the program without any
                complications.
              </i>
            </p>
            <br />
          </div>
        </div>
        <br />
        <h2 id="signUpHeader">
          Interested? Here you can sign up to our affiliate program!
        </h2>
        <div style={{ marginTop: "50px", marginBottom: "50px" }}>
          <AffiliateSignup />
        </div>
        <div>
          <PageFooter />
        </div>
      </div>
    </div>
  );
}

export default Affiliate;
