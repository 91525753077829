import React from "react";
import TinyButton from "./TinyButton";
import soundcloudIcon from "../../recourses/images/soundcloud_icon.png";
import spotifyIcon from "../../recourses/images/spotify-512.png";
import instagramIcon from "../../recourses/images/Insta.png";
import discordIcon from "../../recourses/images/discord-512.png";
import youtubeIcon from "../../recourses/images/youtube.png";
import labelradarIcon from "../../recourses/images/labelradar.png";

export const LinkBar = () => {
  return (
    <div className="linkBar">
      <TinyButton image={soundcloudIcon} link="https://soundcloud.com/scanrecs" />
      <div style={{width: "30px", height: "20px"}}/>
      <TinyButton image={spotifyIcon} link="https://open.spotify.com/user/3156bnqzd2bg2rslm5qljmfmwndi?si=247c94d8f50c4d32" />
      <div style={{width: "30px", height: "20px"}}/>
      <TinyButton image={instagramIcon} link="https://www.instagram.com/scanrecs/" />
      <div style={{width: "30px", height: "20px"}}/>
      <TinyButton image={youtubeIcon} link="https://www.youtube.com/channel/UCJ8PN5U3dqZGO7A9kGDf33Q?sub_confirmation=1" />
      <div style={{width: "30px", height: "20px"}}/>
      <TinyButton image={discordIcon} link="https://discord.gg/frYEEjPDEd" />
      <div style={{width: "30px", height: "20px"}}/>
      <TinyButton image={labelradarIcon} link="https://www.labelradar.com/labels/scanrecords/profile" />
    </div>
  );
}