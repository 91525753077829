import { createAsyncThunk } from "@reduxjs/toolkit"
import SpotifyApi from "../../api/SpotifyApi"

const spotiApi = new SpotifyApi();

export const getArtistInformation = createAsyncThunk(
  'member/getArtistInformation',
  async (_, { getState, rejectWithValue }) => {
    const { member } = getState()
    const spotifyArtistIds = member.spotifyArtistIds
    if ( spotifyArtistIds?.length === 0 ) {
      return []
    }
    try {
      const respArtistInfo = await spotiApi.getMultiplegetArtistInfo(spotifyArtistIds)
      const topTracksInfo = await Promise.all(spotifyArtistIds.map(id => {
        return spotiApi.getTopTracks(id)
      }))
      const relatedInfo = await Promise.all(spotifyArtistIds.map(id => {
        return spotiApi.getRelatedArtists(id)
      }))

      const artistInfo = respArtistInfo?.data?.artists

      const totalInfo = artistInfo.map(artistInfo => {
        artistInfo["top_tracks"] = topTracksInfo.find(trackInfo => Object.keys(trackInfo)[0] === artistInfo.id)?.[artistInfo.id]
        artistInfo["related_artists"] = relatedInfo.find(related => Object.keys(related)[0] === artistInfo.id)?.[artistInfo.id]
        return artistInfo
      })

      return totalInfo
    }
    catch (err) {
      return rejectWithValue(err)
    }
  }
)
