import { Box } from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getArtistInformation } from "../../store/memberState/actions";
import { ArtistCarousel } from "./ArtistCarousel";
import { useAuth0 } from "@auth0/auth0-react";
import { ProfileImage } from "./dashboardComponents/ProfileImage";
import { PopScorePieChart } from "./dashboardComponents/PopScorePieChart";
import { TopTracks } from "./dashboardComponents/TopTracks";
import { RelatedArtists } from "./dashboardComponents/RelatedArtists";

export const SpotifyDashboardView = () => {
  const spotifyArtistIds = useSelector(state => state.member.spotifyArtistIds)

  const { user } = useAuth0();

  const dispatch = useDispatch();

  const artistInfo = useSelector(state => state.member.artistInfo)
  const showId = useSelector(state => state.member.showId)
 
  const getThisArtistInfo = () => {
    if (artistInfo && artistInfo.length > 0) {
      return artistInfo.filter(info => info?.id === spotifyArtistIds[showId])[0]
    }
    return {}
  }

  const matchingIds = () => {
    if (artistInfo.length === spotifyArtistIds.length) {
      spotifyArtistIds.forEach(id => {
        if (!artistInfo.includes(id)) {
          return false
        }
      })
      return true
    }
    return false
  }

  useEffect(() => {
    if (!artistInfo || artistInfo?.length === 0 || !matchingIds()) {
      dispatch(getArtistInformation())
    }
  }, [])

  return (
    <Box>
      <Box className="flexCollapse" >
        <Box sx={{ overflowX: "hidden"}}>
          <h2 style={{margin: "20px 0px" }}>Spotify Analytics</h2>
        </Box>
        <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "space-around"}}>
          <ArtistCarousel />
        </Box>
        
      </Box>
      
      <Box className="contentSubcontainers">
        <Box className="contentSubcontainer" sx={{marginBottom: "30px"}}>
          <ProfileImage getThisArtistInfo={getThisArtistInfo} />
          
        </Box>

        <Box className="contentSubcontainer" sx={{marginBottom: "30px"}}>
          <PopScorePieChart getThisArtistInfo={getThisArtistInfo} />
          <Box sx={{ marginTop: "10px"}}>
            <RelatedArtists getThisArtistInfo={getThisArtistInfo} />
          </Box>
          
        </Box>
      </Box>
      <Box sx={{marginBottom: "30px"}}>
        <TopTracks getThisArtistInfo={getThisArtistInfo} />
      </Box>
      
    </Box>)
}