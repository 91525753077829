import { Box, Tooltip } from "@mui/material"
import React, { useState } from "react"
import { backgroundGray, hoverGray, textGray } from "../../../pages/styleconstants"
import { PopularityLineChart } from "../charts/PopularityLineChart";

export const TopTrackItem = ({track, countIter}) => {

  const [isHover, setIsHover] = useState(false)

  return (
    <Box className="flexCollapse noScrollbar" 
      sx={{ padding: "7px", borderRadius: "10px", backgroundColor: isHover ? hoverGray : backgroundGray, transition: ".2s all linear", overflowX: "scroll"}}
      onMouseEnter={() => {setIsHover(true)}}
      onMouseLeave={() => {setIsHover(false)}}
    >
      <Box sx={{ display: "flex", gap: 2}}>
        <a  href={track?.external_urls?.spotify} rel="noreferrer" target="_blank" > 
          <img src={track?.album?.images?.[2]?.url} alt="Song Album" style={{ height: "50px", width: "50px", borderRadius: "4px"}} />
        </a>
        <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "space-around"}}>
          <a  href={track?.external_urls?.spotify} rel="noreferrer" target="_blank" >
            <p style={{ margin: "0"}}>{track?.name}</p>
          </a>
          <Box sx={{ display: "flex", gap: 0.5 }}>
            {track?.artists.map(artist => {
              countIter += 1
              return (
                <a 
                  key={artist?.id}
                  href={artist?.external_urls?.spotify} 
                  rel="noreferrer" 
                  target="_blank" 
                  style={{ margin: "0", color: textGray, fontSize: "13px"}}
                >
                  {artist?.name}
                  {countIter < track?.artists.length && (
                    <>,</>
                  )}
                </a>)
            })}
          </Box>
        </Box>
      </Box>
      <Box sx={{ display: "flex", gap: 1, padding: "7px 0 0 0", minWidth: "300px"}} >
        <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "space-around" }}>
          <Box sx={{ display: "flex", gap: 0.5 }}>
            <Tooltip arrow title="What is algorithmic popularity?">
              <a 
                href="https://medium.com/protonradio/introducing-spotify-popularity-scores-know-which-artists-can-help-you-grow-879cbf78fb98#:~:text=You%27re%20likely%20already%20familiar,temperature”%20in%20the%20almighty%20algorithm." 
                target="_blank" 
                rel="noreferrer"
                style={{ margin: "0", color: textGray, fontSize: "13px"}}
              >
                Popularity:
              </a>
            </Tooltip>
            <p style={{ margin: "0", color: textGray, fontSize: "13px"}}>{track?.popularity}/100</p>
          </Box>
        </Box>
        <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "space-around", width: "200px"}}>
          <PopularityLineChart popScore={track?.popularity} />
        </Box>
        <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "space-around"}}>
          <Tooltip arrow title="What is isrc?">
            <a href="https://isrc.ifpi.org/en/" target="_blank" rel="noreferrer" style={{ margin: "0", color: textGray, fontSize: "13px"}}>isrc: {track?.external_ids?.isrc}</a>
          </Tooltip>
        </Box>
      </Box>
      
      
    </Box>)
}