import "./App.css";
import React from "react";
import { NavBar } from "./components/allPages/NavBar";
//Images
import { LinkBar } from "./components/homePage/LinkBar";
import { HomePageContent } from "./components/homePage/HomePageContent";
import { HomePageFooter } from "./components/homePage/HomePageFooter";
import { HomePageBgVideo } from "./components/homePage/HomePageBgVideo";

function Home() {
  return (
    <div className="home">
      <div className="container" style={{ overflow: "hidden", width: "100vw" }}>
        <HomePageBgVideo />
        <NavBar />
        <HomePageContent />
        <LinkBar />
        <HomePageFooter />
        {/*Hidden Trustpilot Widget */}
        {/*<TrustpilotWidget />*/}
      </div>
    </div>
  );
}

export default Home;
