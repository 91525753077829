export const VideoHeader = ({ pageColor }) => {
  const xOffset = "20px";
  const yOffset = "60px";
  const headerHeight = "430px";
  const rotation = "3deg";

  return (
    <div style={{ transform: `translate(-${xOffset},  -${yOffset})` }}>
      <div
        style={{
          overflow: "hidden",
          height: headerHeight,
          width: "110vw",
          transform: `rotate(-${rotation})`,
        }}
      >
        <div style={{ transform: `rotate(${rotation})`, height: headerHeight }}>
          <div
            className="videoWrapper"
            style={{
              width: "100vw",
              height: "100vh",
              backgroundColor: "black",
              zIndex: "-1",
              backgroundImage: "url(/breath_ctrl.png)",
              backgroundPosition: "center",
              filter: "blur(5px)",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              transform: `translate(${xOffset},  ${yOffset})`,
            }}
          />

          <div
            className="videoDimmer"
            style={{
              width: "200vw",
              height: "200vh",
              position: "absolute",
              top: "0",
              backgroundColor: "rgba(0, 0, 0, 0.4)",
            }}
          />
          <div
            className="topGradient"
            style={{
              backgroundImage: "linear-gradient(black, rgba(0, 0, 0, 0))",
              height: "150px",
              width: "200vw",
              zIndex: "1",
              position: "absolute",
              top: "0px",
            }}
          />
        </div>
        <div
          style={{
            position: "absolute",
            bottom: "0",
            left: "0",
            zIndex: "1",
            height: "50px",
            width: "200vw",
            backgroundImage: `linear-gradient(rgba(11,13,28,0), ${pageColor})`,
          }}
        />
      </div>
    </div>
  );
};
