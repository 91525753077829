import axios from 'axios';

class SpotifyApi {
  getConfig = async() => {
    const response = await axios.post(
      'https://accounts.spotify.com/api/token',
      new URLSearchParams({
        'grant_type': 'client_credentials',
        'client_id': process.env.REACT_APP_SPOTIFY_CLIENT_ID,
        'client_secret': process.env.REACT_APP_SPOTIFY_CLIENT_SECRET,
        "Content-Type": "application/x-www-form-urlencoded"
      })
    )
    return { headers: { Authorization: `Bearer ${response.data.access_token}` } };
  }

  getMultiplegetArtistInfo = async(artistIds) => {
    if (artistIds) {
      let idString= ""
      let countIter = 0
      artistIds.forEach(id => {
        idString += id
        if (countIter < artistIds?.length-1) {
          idString += ","
        }
        countIter += 1
      })
      const config = await this.getConfig()
      config["params"] =  {
        'ids': idString,
      }

      return await axios.get(
        'https://api.spotify.com/v1/artists',
        config);
    }
    return {func: "SpotifyAPI.getMultipleArtistInfo", err: "ids missing"}
  } 

  getArtistSearch = async(artistName) => {
    const config = await this.getConfig()
    config["params"] =  {
      'q': `artist:${artistName}`,
      'type': 'artist'
    }

    return await axios.get(
      'https://api.spotify.com/v1/search',
      config);
  } 

  getTopTracks = async(id) => {
    const config = await this.getConfig()
    config["params"] =  {
      'market': 'US'
    }

    const respTracks = await axios.get(
      `https://api.spotify.com/v1/artists/${id}/top-tracks`,
      config);
      
    return {[id]: respTracks?.data?.tracks}
  }

  getRelatedArtists = async(id) => {
    const config = await this.getConfig()
    config["params"] =  {
      'market': 'US'
    }

    const respArtists = await axios.get(
      `https://api.spotify.com/v1/artists/${id}/related-artists`,
      config);
      
    return {[id]: respArtists?.data?.artists}
  }

  validateArtistId = async(id) => {
    const config = await this.getConfig()
    try {
      await axios.get(
      `https://api.spotify.com/v1/artists/${id}`,
      config);
      return true
    }
    catch (err) {
      return false
    }
    
      
    
  }

}

export default SpotifyApi;