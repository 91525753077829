import { Box } from "@mui/material";
import React from "react";
import { DASHBOARD_VERSION } from "../../utils/constants";
import { PageFooter } from "../allPages/PageFooter";

export const DashboardFooter = () => {
  return (
    <Box sx={{ position: "relative"}}>
      <Box sx={{position: "absolute", top: "65px", color: "#aaa", right: "0px"}}>Dashboard: {DASHBOARD_VERSION}</Box>
      <PageFooter />
    </Box>
  )
}