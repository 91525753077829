import { Box } from "@mui/material";
import React from "react";
import { formatFollowers } from "../../../utils/format";

export const ProfileImage = ({getThisArtistInfo}) => {
  return (
    <Box sx={{ position: "relative" }}>
      <Box sx={{ position: "absolute", bottom: "3.4px", left: "0px", width: "100%"}}>
        <Box 
          sx={{ 
            paddingTop: "20px", 
            paddingBottom: "30px", 
            paddingLeft:"30px", 
            backgroundImage: "linear-gradient(rgba(0,0,0,0.3), rgba(0,0,0,0.9)) ",
            borderRadius: "30px"
          }}
        >
          <Box>
            <h2 style={{margin: "0px", lineHeight: "35px" }}>{getThisArtistInfo()?.name}</h2>
          </Box>
          <p style={{margin: "0px", padding: "0px"}}> <b>{formatFollowers(getThisArtistInfo()?.followers?.total)}</b> Follower</p>
          
        </Box>
      </Box>
      <a href={getThisArtistInfo()?.external_urls?.spotify} rel="noreferrer" target="_blank">
        <img src={getThisArtistInfo()?.images?.[0]?.url} alt="large spotify profile" 
          style={{width: "100%", height: "auto", aspectRatio: 1/1, borderRadius: "30px", objectFit: "cover"}} 
        />
      </a>
    </Box>)
}