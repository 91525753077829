import { Box, Button } from "@mui/material";
import { doc, setDoc } from "firebase/firestore";
import React, { useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useDispatch, useSelector } from "react-redux";
import SpotifyApi from "../../api/SpotifyApi";
import { getArtistInformation } from "../../store/memberState/actions";
import { darkBlue, outlineGray, selectedGray } from "../../pages/styleconstants";
import { db } from "../../firebase/firebase";

export const RequestSpotifyArtist = () => {

  const spotiApi = new SpotifyApi();
  const dispatch = useDispatch();
  
  const { user } = useAuth0()

  const [inputContent, setInputContent] = useState("")
  const [idInputContent, setIdInputContent] = useState("")
  const [selectedId, setSelectedId] = useState("")
  const [searchList, setSeatchList] = useState([])
  const [openIdSearch, setOpenIdSearch] = useState(false)
  
  const spotifyArtistIds = useSelector(state => state.member.spotifyArtistIds)

  const addSpotifyId = async(idToAdd) => {
    if (!spotifyArtistIds.includes(idToAdd)) {
      await setDoc(doc(db, "user", user.name), {
        SpotifyIDs: [...spotifyArtistIds, idToAdd]
      });
    }
  }

  const handleClick = (e) => {
    e.preventDefault();
    let getInfo = true
    if (!spotifyArtistIds || spotifyArtistIds.length === 0) {
      getInfo = false
    }
    addSpotifyId(selectedId).then(() => {
      if (getInfo) {
        dispatch(getArtistInformation())
      }
      setInputContent("")
    })
  }

  const handleIdValidateClick = (e) => {
    e.preventDefault();
    spotiApi.validateArtistId(idInputContent).then(isValid => {
      if (isValid) {
        let getInfo = true
        if (!spotifyArtistIds || spotifyArtistIds.length === 0) {
          getInfo = false
        }
        addSpotifyId(idInputContent).then(() => {
          if (getInfo) {
            dispatch(getArtistInformation())
          }
          setIdInputContent("")
        })
      }
      else {
        alert("Your ID is Invalid, please try again.")
      }
    })
  }

  const handleIdChange = e => {
    setIdInputContent(e.target.value)
  }

  const handleChange = e => {
    setInputContent(e.target.value)
    handleSearch({artistName: e.target.value})
  }

  const handleSearch = ({artistName}) => {
    spotiApi.getArtistSearch(artistName).then(resp => {
      setSeatchList(resp.data?.artists?.items)
    })
  }

  const getSearchItemBackground = (searchItem) => {
    if (spotifyArtistIds.includes(searchItem.id)) {
      return darkBlue
    }
    return selectedId === searchItem.id ? selectedGray : "black"
  }

  return (<Box sx={{display: "flex", flexDirection: "column"}}>
    <Box sx={{ display: "flex", justifyContent: "space-between"}}>
      <h2>Add a Spotify Profile</h2>
      <Button onClick={() => {setOpenIdSearch(!openIdSearch)}} sx={{margin: "auto 0", color: "white"}} color="inherit" variant="outlined">
        {!openIdSearch && (
          <>I cannot find my profile</>
        )}
        {openIdSearch && (
          <>Back to profile search</>
        )}
      </Button>
    </Box>

    {openIdSearch && (
      <>
        <label>Type in a valid Spotify ID - <a href="https://support.tunecore.com/hc/en-us/articles/360040325651-How-do-I-find-my-Artist-ID-for-Spotify-and-iTunes-" target="_blank" rel="noreferrer">How do I find my ID?</a></label>
        <input name="spotifyId" type="text" value={idInputContent} onChange={e => {handleIdChange(e)}} />
        <Button
          onClick={handleIdValidateClick}
          sx={{ backgroundColor: "white", margin: "20px"}}
        >
          Validate Spotify ID
        </Button>
      </>
    )}

    {!openIdSearch && (
      <>
        <label>Search for a spotify artist profile</label>
        <input name="spotifyLink" type="text" value={inputContent} onChange={e => {handleChange(e)}} />
        <Button 
          onClick={handleClick} 
          disabled={selectedId === ""}
          sx={{ backgroundColor: "white", margin: "20px"}}
        >
          Add Spotify Profile
        </Button>
        {/*List of Searches*/}
        <Box sx={{display: "flex", flexDirection: "column", gap: 1}}>
          {searchList.map(searchItem => {
          return (
            <button 
              style={{ 
                border: "none", 
                color: "white", 
                padding: "0", 
                cursor: spotifyArtistIds.includes(searchItem.id) ? "" : "pointer",
                backgroundColor: getSearchItemBackground(searchItem),
                marginBottom: "5px",
                borderRadius: "5px",
                outline: outlineGray,
              }} 
              disabled={spotifyArtistIds.includes(searchItem.id)}
              onClick={() => {setSelectedId(searchItem.id)}}
              key={searchItem.id} 
              
            >
              <Box 
                className="flexCollapse"
                sx={{
                  transition: "all 0.1s linear",
                  padding: "20px", 
                  backgroundColor: getSearchItemBackground(searchItem),
                  margin: "0px",
                  borderRadius: "5px",
                }}
                
              >
                <Box
                  sx={{
                    display: "flex", 
                    gap: 2
                  }}
                >
                  <img src={searchItem.images[2]?.url} height="25px" width="25px" style={{borderRadius: "50%"}} alt="artist profile"/>
                  <Box sx={{ fontSize: "17px", display: "flex", flexDirection: "column", justifyContent: "space-around"}}>{searchItem.name}</Box>
                </Box>
                
                <Box sx={{ color: "gray", display: "flex", flexDirection: "column", justifyContent: "space-around"}}>ID: {searchItem.id}</Box>
              </Box>
            </button>)
        })}
        </Box>
      </>
    )}
  </Box>)
}