import "./page.css"
import React from 'react';
import { VideoHeader } from '../components/allPages/VideoHeader';
import { pageColor } from "./styleconstants";
import { PageFooter } from "../components/allPages/PageFooter";
import { NavBar } from "../components/allPages/NavBar";

function Contact() {
  return (
    <div className="page" style={{ backgroundColor: pageColor}}>
      <VideoHeader pageColor={pageColor} />
      <NavBar />
      <div className="headerTitle">
        <h1 className="headerText">Contact</h1>
      </div>
      <div className='contentContainer'>
        <div className="contentSubcontainers">
          <div className="contentSubcontainer">
          <h3>Contact</h3>
            <p>
              We are always happy to hear from our fans, artists, and industry professionals. 
              If you have any questions, comments, or just want to say hello, please feel free to reach out to us at
              <a href="mailto:contact@scanrecs.com?body=Dear team at Scan Records," target="_blank" rel="noreferrer"> contact@scanrecs.com</a>.
            </p>
            <h3>Submissions</h3>
            <p>
              If you are an artist interested in submitting your music for consideration, please send your demos to
              <a href="mailto:submit@scanrecs.com?body=Dear team at Scan Records," target="_blank" rel="noreferrer"> submit@scanrecs.com</a>
              , or alternatively use 
              <a href="https://www.labelradar.com/labels/scanrecords/profile" target="_blank" rel="noreferrer"> LabelRadar</a>, 
              a submission platform that allows us to streamline the process of receiving and reviewing music submissions.
            </p>
            <p>
              Please note that due to the high volume of submissions we receive, we may not be able to respond to every submission. 
              But we do listen to all of the music we receive and will be in touch if we are interested in working with you.
            </p>
            
          </div>
          <div className="contentSubcontainer">
            <h3>More about Labelradar</h3>
            <p>
              LabelRadar is a great way for artists to submit their music and get noticed by industry professionals like us. 
              With LabelRadar, you can submit your demos directly to us and get feedback on your music. 
            </p>
            <h3>Legal Information</h3>
            <div className="contentSubcontainers" >
              <div className="contentSubcontainer">
                <p>
                  <i>Scan Records</i> <br />
                  CEO: Felix Kniest: <br />
                  felix@scanrecs.com
                </p>
              </div>
              <div className="contentSubcontainer">
                <p>
                   <br /> 
                  Co-Mgm: Olof Sjögren <br />
                  olof@scanrecs.com
                </p>
              </div>
            </div>
            <p>
              Scan Records currently qualifies as a "partnership under the Civil Code" or german "GBR".
            </p>
            <p>
              <i>Thank you for your interest in Scan Records. We look forward to hearing from you!</i>
            </p>
          </div>
        </div>
        <div>
          <PageFooter />
        </div>
      </div>
    </div>
  );
}

export default Contact;