import React, { useEffect, useState } from "react"
import { ResponsiveAppBar } from "../components/artistDashboard/ResponsiveAppBar"
import { Box } from "@mui/material"
import { BlockingMessage } from "../components/artistDashboard/BlockingMessage"
import { useAuth0 } from "@auth0/auth0-react"
import { useDispatch, useSelector } from "react-redux"
import { collection, onSnapshot, query } from "firebase/firestore"
import { setCriticalError, setRemovingArtistLoading, setShowId, setSpotifyApiLoading, setSpotifyArtistIds } from "../store/memberState/slice"
import { RequestSpotifyArtist } from "../components/artistDashboard/RequestSpotifyArtist"
import { SpotifyDashboardView } from "../components/artistDashboard/SpotifyDashboardView"
import { selectedGray } from "./styleconstants"
import ScanrecsApi from "../api/ScanrecsApi"
import { db } from "../firebase/firebase"
import { auth } from "../firebase/firebase"
import { signInWithCustomToken } from "firebase/auth"
import { DashboardFooter } from "../components/artistDashboard/DashboardFooter"
import { SoundcloudDashboardView } from "../components/artistDashboard/SoundcloudDashboardView"

export const ArtistDashboard = () => {
  const dispatch = useDispatch()

  const spotifyArtistIds = useSelector(state => state.member.spotifyArtistIds)
  const [idsLoad, setIdsLoad] = useState(true)
  const spotifyApiLoading = useSelector(state => state.member.spotifyApiLoading)
  const removingArtistLoading = useSelector(state => state.member.removingArtistLoading)
  const criticalError = useSelector(state => state.member.criticalError)
  const pageID = useSelector(state => state.member.pageID)
  const scanrecsApi = new ScanrecsApi()

  const { user, isLoading, isAuthenticated, getAccessTokenSilently } = useAuth0()

  //After login
  useEffect(() => {
    if (user) {
      const q = query(collection(db, "user"))
      //reset ids on load
      dispatch(setSpotifyArtistIds([]))
      dispatch(setShowId(0))
      dispatch(setCriticalError(""))
      dispatch(setSpotifyApiLoading(false))
      dispatch(setRemovingArtistLoading(false))
      // log into firebase auth -- auth will be cached for future writes or reads
      getAccessTokenSilently().then(token => {
        scanrecsApi.getFirebaseToken(token).then(response => {
          signInWithCustomToken(auth, response?.data?.firebaseToken).then(userCredential => {
            const unsub = onSnapshot(q, (querySnapshot) => {
            let itemsProcessed = 0
            if (querySnapshot.docs.length > 0) {
              querySnapshot.docs.forEach(doc => {
                itemsProcessed += 1
                if (doc.id === user.name) {
                  if (doc.data()?.SpotifyIDs) {
                    dispatch(setSpotifyArtistIds(doc.data().SpotifyIDs))
                  }
                }
                if (itemsProcessed === querySnapshot.docs.length) {
                  setIdsLoad(false)
                }
              })
            }
            else {
              setIdsLoad(false)
            }
          });
          // unsub()
          })
        }).catch(err => {
          dispatch(setCriticalError("Scanrecs API token call failed, please try again later."))
        })
      })
      
    }
  }, [user])

  if ( criticalError !== "" ) {
    return <BlockingMessage message={criticalError} />
  }

  // Auth
  if ( isLoading ) {
    return <BlockingMessage message="Authenticating..." />
  }
  if (!isAuthenticated) {
    return <BlockingMessage message="You are not logged in. Return to the Homepage and login again or try another browser." />
  }
  // Get Data from db
  if ( idsLoad ) {
    return <BlockingMessage message="Fetching Artists..." />
  }

  if ( spotifyApiLoading ) {
    return <BlockingMessage message="Fetching Spotify API..." />
  }
  
  if ( removingArtistLoading ) {
    return <BlockingMessage message="Removing..." />
  }
  

  return (
  <Box>
    <style>
    {
      `.customScrollbar::-webkit-scrollbar {
        height: 10px;
        width: 12px;
      }
    
      .customScrollbar::-webkit-scrollbar-track {
        background: rgba(0,0,0,0);
        border-radius: 6px;
        height: 10px;
        width: 12px;
      }
      
      .customScrollbar::-webkit-scrollbar-thumb {
        background: ${selectedGray};
        border-radius: 6px;
      }
      
      .noScrollbar::-webkit-scrollbar {
        height: 0px;
        width: 0px;
      }
    
      .noScrollbar::-webkit-scrollbar-track {
        height: 0px;
        width: 0px;
      }
      `
    }
    </style>
    <ResponsiveAppBar />
    
    {pageID === "spotify" && (
      <Box className="contentContainer">
        {spotifyArtistIds?.length === 0 && (
          <RequestSpotifyArtist />
        )}
        {spotifyArtistIds?.length !== 0 && (
          <SpotifyDashboardView />
        )}
        <DashboardFooter />
      </Box>
    )}
    {pageID === "soundcloud" && (
      <Box className="contentContainer">
        <SoundcloudDashboardView />
      </Box>
    )}
    
    
    
  </Box>)
}