import { Box } from "@mui/material";
import React from "react";
import { outlineGray} from "../../../pages/styleconstants";
import { TopTrackItem } from "./TotTrackItem";

export const TopTracks = ({getThisArtistInfo}) => {

  

  const renderList = () => {
    return (
      <Box sx={{display: "flex", flexDirection: "column", gap: 2}}>
        {getThisArtistInfo()?.top_tracks?.map(track => {
          let countIter = 0
          return (<TopTrackItem  key={track?.uri} track={track} countIter={countIter} />)
        })}

      </Box>)
  }

  return (
    <Box sx={{ position: "relative", padding: "20px", backgroundColor: "black", borderRadius: "30px", outline: outlineGray}}>
      <h3 style={{margin: "0", marginBottom: "20px"}}>Your Top Tracks</h3>
      {renderList()}
    </Box>
  )
}