import React from "react";
import { Box } from "@mui/material";

import { PieChart, Pie, Cell, ResponsiveContainer } from 'recharts';


export const PopularityPieChart = ({popScore}) => {

  const data = [
    { name: "Popularity", value: popScore, fill: "lightgreen" },
    { name: "", value: 100-popScore, fill: "lightblue" }
  ]

  return (
    <Box sx={{ height: "175px", width: "175px"}}>
      <ResponsiveContainer width="100%" height="100%">
        <PieChart width={400} height={400}>
          <Pie 
            data={data} 
            dataKey="value" 
            cx="50%" cy="50%" 
            innerRadius={65} 
            outerRadius={75} 
            fill="#82ca9d"
            startAngle={90}
            endAngle={-270}
            isAnimationActive 
            paddingAngle={3}
            stroke="none"
            cornerRadius={40}
            animationBegin={0}
          >
          </Pie>
        </PieChart>
      </ResponsiveContainer>
    </Box>)
}