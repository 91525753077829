import React from "react";
import { Box } from "@mui/material";

export const RelatedArtists = ({getThisArtistInfo}) => {

  const renderArtist = (artist) => {
    return <a key={artist?.id} href={artist?.external_urls?.spotify} rel="noreferrer" target="_blank" >
      <img src={artist?.images?.[0]?.url} style={{ height: "85px", width: "85px",aspectRatio: 1/1 , borderRadius: "50%", objectFit: "cover" }} alt="Related Artists" />
      <Box sx={{ width: "100%", display: "flex", justifyContent: "space-around" }}>
        <p style={{fontSize: "14px", margin: "0"}}>{artist?.name}</p>
      </Box>
      
    </a>
  }

  return (
  <Box>
    <h3>Related Artists</h3>
      <Box className="customScrollbar" sx={{ width: "100%", overflowX: "scroll", transition: "all 0.2s linear" }}>
        <Box sx={{ display: "flex", gap: 2, marginBottom: "7px"}}>
          {getThisArtistInfo()?.related_artists?.map(artist => {
            return renderArtist(artist)
          })}
        </Box>
      </Box>
    </Box>)
      
}