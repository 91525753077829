import { Box, Modal } from "@mui/material";
import React from "react";

export const TinyModal = ({ component, open, setOpen }) => {
  return (
    <Modal
        open={open}
        onClose={() => {setOpen(false)}}
      >
        <Box
          sx={{ 
            maxHeight: "50vh", 
            maxWidth: "90vw", 
            backgroundColor: "black", 
            position: "absolute", 
            top: "50%", 
            left: "50%", 
            transform: "translate(-50%,-50%)",
            borderRadius: "20px",
            outline: "1px #333 solid",
            padding: "30px",
            color: "white"
          }}
        >
          {component}
        </Box>
      </Modal>
  )
}