import React from "react";

export const HomePageBgVideo = () => {
  return (
    <>
      {/*Video and transparent black gradients */}
      <div
        className="videoWrapper"
        style={{
          width: "100vw",
          height: "100vh",
          backgroundColor: "black",
          zIndex: "-1",
          backgroundImage: "url(/breath_ctrl.png)",
          backgroundPosition: "center",
          filter: "blur(5px)",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      />
      <div
        className="videoDimmer"
        style={{
          width: "100vw",
          height: "100vh",
          position: "absolute",
          top: "0",
          backgroundColor: "rgba(0, 0, 0, 0.4)",
        }}
      />
      <div
        className="topGradient"
        style={{
          backgroundImage: "linear-gradient(black, rgba(0, 0, 0, 0))",
          height: "150px",
          width: "100vw",
          position: "absolute",
          top: "0px",
        }}
      />
      <div
        className="bottomGradient"
        style={{
          backgroundImage: "linear-gradient(rgba(0, 0, 0, 0), black)",
          height: "150px",
          width: "100vw",
          position: "absolute",
          bottom: "0px",
        }}
      />
    </>
  );
};
