import React from "react";
import { FooterDots } from "../allPages/FooterDots";

export const HomePageFooter = () => {
  return (
  <div className="c" style={{
    position: "absolute",
    bottom: "10px",
    left: "10px",
    margin: "0 auto",
    zIndex: "1",
    display: "flex",
    alignItems: "center",
    gap: "15px",
    color: "white",
    paddingRight: "10px"
  }}>
    <div>© Scan Records / {new Date().getFullYear()}</div>
    <FooterDots/>
    <div><a href="./terms">Terms</a></div>
    <FooterDots />
    <div><a href="./privacy">Privacy</a></div>
  </div>)
}