import { Box, Button } from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setRemovingArtistLoading, setShowId } from "../../store/memberState/slice";
import { getArtistInformation } from "../../store/memberState/actions";
import { doc, setDoc } from "firebase/firestore";
import { useAuth0 } from "@auth0/auth0-react";
import { db } from "../../firebase/firebase";

export const RemoveCheck = ({setOpen}) => {

  const dispatch = useDispatch();

  const spotifyArtistIds = useSelector(state => state.member.spotifyArtistIds)
  const showId = useSelector(state => state.member.showId)
  
  const { user } = useAuth0();

  const removeSpotifyId = async() => {
    const newIds = spotifyArtistIds.filter(id => id !== spotifyArtistIds[showId])
    await setDoc(doc(db, "user", user.name), {
      SpotifyIDs: newIds
    });
  }

  const handleYesClick = (e) => {
    e.preventDefault()
    dispatch(setRemovingArtistLoading(true))
    removeSpotifyId().then(() => {
      dispatch(setShowId(0))
      dispatch(getArtistInformation())
      dispatch(setRemovingArtistLoading(false))
    })
    setOpen(false)
  }

  return (
    <Box>
      <p>Are you sure, you want to remove this artist?</p>
      <Box sx={{display: "flex", gap: 3, marginTop: "15px"}}>
        <Button variant="contained" sx={{color: "black", backgroundColor: "white"}} onClick={() => {setOpen(false)}}>No</Button>
        <Button sx={{color: "white"}} onClick={(e) => {handleYesClick(e)}}>Yes</Button>
      </Box>
    </Box>)
}