import "./page.css"
import React from 'react';
import { VideoHeader } from '../components/allPages/VideoHeader';
import { pageColor } from "./styleconstants";
import { PageFooter } from "../components/allPages/PageFooter";
import { NavBar } from "../components/allPages/NavBar";
import { Date } from "../components/allPages/Date";

function TermsOfService() {
  return (
    <div className="page" style={{ backgroundColor: pageColor}}>
      <VideoHeader pageColor={pageColor} />
      <NavBar />
      <div className="headerTitle">
        <h1 className="headerText">Terms of Service</h1>
      </div>
      <div className='contentContainer'>
        <Date date="21.07.2023" />
        <h3>Introduction</h3>
        <p>
        Welcome to Scan Records. We are a Record and Sample Label that hosts an Artist Dashboard on scanrecs.com and sells digital products on shop.scanrecs.com, 
        an online store which is managed by 
        the third-party platform payhip.com by "Payip Limited". Therefore Payhip Limited is responsible for handling
        transactions and product distribution. Scan Records owns all rights to the domain "www.scanrecs.com" and all subdomains. 
        By accessing or using our products, 
        you agree to be bound by the following terms and conditions ("Terms of Service").
        </p>
        <h3>1. Product Description and Availability</h3>
        <p>
          All products sold on <a href="https://shop.scanrecs.com" target="_blank" rel="noreferrer">shop.scanrecs.com</a> by 
          Scan Records are digital products in the form of sound files and/or plug-in presets bundled in folders and zip-archives. 
          These products are available for download after a successful 
          purchase. Scan Records makes every effort to ensure that our products are available and functional at all times, 
          but we do not guarantee the availability or reliability of our products.
        </p>
        <h3>2. Payment and Refunds</h3>
        <p>
          All payments for our products must be made through payhip.com (Payhip Limited), which handles the processing and secure storage of 
          payment information. If you have any issues with a payment or a product, please contact payhip.com's customer 
          support for assistance. <br /> <br />
          Scan Records does not offer refunds for any digital products. All sales are final, and no refunds will be issued.
        </p>
        <h3>3. Product Use</h3>
        <p>
          By purchasing a product from Scan Records, you agree to use the product in accordance with the intended 
          purpose as described in the product description. You are not allowed to redistribute, sell, or otherwise exploit 
          our products for commercial gain. Any unauthorized use may result in legal action.
        </p>
        <h3>4. Intellectual Property</h3>
        <p>
          All products sold by Scan Records are protected by copyright and intellectual property laws. You do not have 
          the right to modify, reproduce, or distribute our products without written consent from Scan Records.
        </p>
        <h3>5. Limitation of Liability</h3>
        <p>
          Scan Records shall not be liable for any damages arising from the use of our products. You assume all risks 
          and responsibilities associated with using our products.
        </p>
        <h3>6. Changes to the Terms of Service</h3>
        <p>
          Scan Records reserves the right to modify these Terms of Service at any time without prior notice. 
          Your continued use of our products constitutes acceptance of the updated terms.
        </p>
        <h3>7. Governing Law</h3>
        <p>
          These Terms of Service shall be governed by and construed in accordance with the laws of the state 
          in which Scan Records is located.
        </p>
        <h3>8. Contact Information</h3>
        <p>
          If you have any questions or concerns about these Terms of Service, please contact us at <a href="./contact">
            contact@scanrecs.com</a>.
        </p>
        <p>
          <i>By using our products, you acknowledge that you have read, understood, and agreed to these Terms of Service. 
          Thank you for choosing Scan Records.</i>
        </p>
        <div>
          <PageFooter />
        </div>
      </div>
    </div>
  );
}

export default TermsOfService;