import "./page.css"
import React from 'react';
import { VideoHeader } from '../components/allPages/VideoHeader';
import { pageColor } from "./styleconstants";
import { PageFooter } from "../components/allPages/PageFooter";
import { NavBar } from "../components/allPages/NavBar";

function About() {
  return (
    <div className="page" style={{ backgroundColor: pageColor}}>
      <VideoHeader pageColor={pageColor} />
      <NavBar />
      <div className="headerTitle">
        <h1 className="headerText">About Scan Records</h1>
      </div>
      <div className='contentContainer'>
        <div className="contentSubcontainers">
          <div className="contentSubcontainer">
          <h3>About Us</h3>
            <p>Scan Records is a music label dedicated to discovering and promoting the best in underground electronic music. 
            We specialize in a wide range of genres, from experimental to Dubstep and everything in between. 
            Our team is made up of passionate music lovers who are always on the lookout for the next big thing.
            </p>
            <p>
            In addition to our label, we also offer a selection of high-quality sample packs for producers and beatmakers to use in their own productions.
            Most of these packs are made by our artists, for which we act like a distributor.
            Because at Scan Records, we believe that music should be accessible to everyone and strive to provide a platform for emerging artists to 
            showcase their talent.
            </p>
            <p>
            <i>Join us on our journey and discover the sounds of the future.</i>
            </p>
          </div>
          <div className="contentSubcontainer">
          <h3>Our Sub-Labels</h3>
            <p>
              In addition to our main label, we also have two sub-labels, ASPIRATION and BARCODE.
            </p>
            <p> 
              <b>ASPIRATION</b> is our ambient and indie music label, dedicated to showcasing the best in experimental and alternative sounds. 
            </p>
            <p>
              <b>BARCODE</b> is our house and garage label, home to the most exciting and innovative beats in the genre. 
            </p>
            <p>
              Each sub-label has its own team of curators, carefully selecting and promoting only the most exceptional music. 
              With our main label and sub-labels, Scan Records is your one-stop-shop for all things underground electronic music. 
              Whether you're a fan of ambient soundscapes, indie gems, house beats or garage rhythms, we've got something for you.
            </p>
          </div>
          
        </div>
        <div>
          <PageFooter />
        </div>
      </div>
    </div>
  );
}

export default About;