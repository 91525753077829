import "./page.css"
import React from 'react';
import { VideoHeader } from '../components/allPages/VideoHeader';
import { pageColor } from "./styleconstants";
import { PageFooter } from "../components/allPages/PageFooter";
import { NavBar } from "../components/allPages/NavBar";
import { Date } from "../components/allPages/Date";

function PrivacyPolicy() {
  return (
    <div className="page" style={{ backgroundColor: pageColor}}>
      <VideoHeader pageColor={pageColor} />
      <NavBar />
      <div className="headerTitle">
        <h1 className="headerText">Privacy Policy</h1>
      </div>
      <div className='contentContainer'>
        <Date date="19.11.2023" />
        <h3>Introduction</h3>
        <p>
        At Scan Records, we understand the importance of protecting the privacy of our customers and users. 
        This Privacy Policy explains how we collect, use, and disclose personal information collected on our website, our shop 
        shop.scanrecs.com, hosted and managed by Payhip Limited and our submission platform admin.scanrecs.com.
        </p>
        <h3>1. Collection of Personal Information</h3>
        <p>
          We collect personal information from users in several ways, including:
        </p>
        <p>
          <ul>
            <li>When signing up for our Artist Dashboard on scanrecs.com</li>
            <li>When purchasing a product through shop.scanrecs.com</li>
            <li>When subscribing to our newsletter on shop.scanrecs.com</li>
            <li>When applying to become a shop affiliate on shop.scanrecs.com</li>
            <li>When submitting any release related information on admin.scanrecs.com</li>
          </ul>
          The personal information we collect may include your name, email address, payment information and more, based only on the 
          information you enter yourself during the mentioned above processes.  
        </p>
        <h3>2. Use of Personal Information</h3>
        <p>
        We use the personal information we collect to provide our products and services, communicate with our 
        customers and users, and improve our website and platform. This may include us rarely using your email address to send 
        promotional materials or updates on our products and services.
        </p>
        <h3>3. Sharing of Personal Information</h3>
        <p>
        We do not sell, trade, or otherwise actively transfer your personal information to third parties and do everything in our power to protect our data
        pipelines from intruders. Your personal 
        information might get picked up by third-party service providers, who provide the platform on which our shop has 
        been built, such as Payhip Limited, in order to properly process the money-transfer. 
        <br />
        Files you have sent in during the release process such as logos and audio files and files created by us for your release might also be picked up and scanned
        to fit their guidelines (mostly including scans for malitious content like child pornography) by Google, 
        due to our use of Google Drive to share and store files within a reasonable time frame around the release. 
        For more information on Googles privacy policy and terms of service visit <a href="https://policies.google.com/privacy" target="_blank" rel="noreferrer">https://policies.google.com/privacy</a> (last visited 19.11.2023).
        </p>
        <h3>4. Security of Personal Information</h3>
        <p>
        We take the security of your personal information seriously and implement appropriate measures to protect 
        against unauthorized access, alteration, disclosure, or destruction of your personal information.
        </p>
        <h3>5. Retention of Personal Information</h3>
        <p>
        We retain your personal information for as long as necessary to provide our products and services and to 
        comply with legal requirements.
        </p>
        <h3>6. Your Rights</h3>
        <p>
        Under the General Data Protection Regulation (GDPR), EU residents have certain rights with respect to their 
        personal information, including the right to access, correct, or delete their personal information. If you 
        would like to exercise these rights, please contact us at contact@scanrecs.com. In addition, we comply with 
        applicable laws in the United States, such as the California Consumer Privacy Act (CCPA).
        </p>
        <h3>7. Changes to the Privacy Policy</h3>
        <p>
        We may update this Privacy Policy from time to time. If we make any changes, we will update the "Effective Date" 
        at the top of this Privacy Policy and post the revised policy on our website. Your continued use of our website 
        and platform constitutes acceptance of the updated Privacy Policy.
        </p>
        <h3>8. Contact Information</h3>
        <p>
          If you have any questions or concerns about these Terms of Service, please contact us at <a href="./contact">
            contact@scanrecs.com</a>.
        </p>
        <p>
          <i>Thank you for choosing Scan Records. We value your trust and are committed to protecting your privacy.</i>
        </p>
        <div>
          <PageFooter />
        </div>
      </div>
    </div>
  );
}

export default PrivacyPolicy;