import React from "react"

export const AffiliateSignup = () => {

  const handleClick = (e) => {
    e.preventDefault();
    window.open(
      "https://payhip.com/auth/register/af63bf3a5a818d1",
      "_blank" // <- This is what makes it open in a new window.
    );
  }
  return (
      <div style={{width: "100%"}}>
        <button className="buttonLinkDiv" onClick={(e) => {handleClick(e)}} style={{ width: "100%" }}>SIGN UP</button>
      </div>
  );
}