import React from "react";
import scanLogo from "../../recourses/images/scan_logo.png"
import mobileMenu from "../../recourses/images/icons/mobile menu.png"

export const NavBar = () => {
  
  const handleClick = ({e, href, target}) => {
    e.preventDefault();
    window.open(
      href,
      target // <- This is what makes it open in a new window.
    );
  }

  const [menuOpen, setMenuOpen] = React.useState(false);

  const handleOpenMobile = () => {
    setMenuOpen(!menuOpen)
  }

  return (
    <>
      <div className="navigationContainer">
        <div style={{ display: "flex", justifyContent: "space-around"}}>
          <button className="navButtonHome" onClick={(e) => handleClick({e, href: "./", target: "_self"})}>
            <div style={{ display: "flex", justifyContent: "space-between", gap: "4px", alignItems: "center"}}>
              <img src={scanLogo} width="20px" height="20px" alt="" style={{ transform: "translateY(-5%)"}} />
            </div>
          </button>
          <button className="navButton" onClick={(e) => handleClick({e, href: "https://shop.scanrecs.com", target: "_self"})}>SHOP</button>
          <button className="navButton" onClick={(e) => handleClick({e, href: "./affiliate", target: "_self"})}>AFFILIATE</button>
          <button className="navButton" onClick={(e) => handleClick({e, href: "./about", target: "_self"})}>ABOUT</button>
          <button className="navButton" onClick={(e) => handleClick({e, href: "./contact", target: "_self"})}>CONTACT</button>
        </div>
      </div>
      <div className="navigationContainerMobile">
        {!menuOpen && (
        <button className="navButtonMobile" onClick={()=> {handleOpenMobile()}}>
          <img src={mobileMenu} alt="mobile menu" width="20px" height="20px" />
        </button>)}
        {menuOpen && (
          <div className="mobileMenu">
            <div>
            <button className="navButtonMobile" onClick={()=> {handleOpenMobile()}}>
              <img src={mobileMenu} alt="mobile menu" width="20px" height="20px" />
            </button>
            </div>
            <div className="navLinksMobile">
              <button className="navButton" onClick={(e) => handleClick({e, href: "./", target: "_self"})}>HOME</button>
              <button className="navButton" onClick={(e) => handleClick({e, href: "https://shop.scanrecs.com", target: "_self"})}>SHOP</button>
              <button className="navButton" onClick={(e) => handleClick({e, href: "./affiliate", target: "_self"})}>AFFILIATE</button>
              <button className="navButton" onClick={(e) => handleClick({e, href: "./about", target: "_self"})}>ABOUT</button>
              <button className="navButton" onClick={(e) => handleClick({e, href: "./contact", target: "_self"})}>CONTACT</button>
            </div>
            
          </div>
        )}
      </div>
    </>
      
  );
}