import React from "react"
import { Box } from "@mui/material"

export const BlockingMessage = ({message}) => {
  return (
    <Box sx={{display: "flex", justifyContent: "space-around", height: "100vh"}}>
      <Box sx={{display: "flex", flexDirection: "column", justifyContent: "space-around" }}>
        <h3 style={{ color: "white", margin: "0"}}>{message}</h3>
      </Box>
    </Box>)
}