import { Box, IconButton, Modal, Tooltip } from "@mui/material";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import NavigateNextRoundedIcon from '@mui/icons-material/NavigateNextRounded';
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded';
import { setShowId } from "../../store/memberState/slice";
import { RequestSpotifyArtist } from "./RequestSpotifyArtist";
import { AdjustedModal } from "../allPages/AdjustedModal";
import { TinyModal } from "../allPages/TinyModal";
import { RemoveCheck } from "./RemoveCheck";
import { outlineGray } from "../../pages/styleconstants";

export const ArtistCarousel = () => {
  const showId = useSelector(state => state.member.showId)
  const spotifyArtistIds = useSelector(state => state.member.spotifyArtistIds)
  const artistInfo = useSelector(state => state.member.artistInfo)

  const [openAddArtist, setOpenAddArtist] = useState(false)
  const [openRemove, setOpenRemove] = useState(false)


  const dispatch = useDispatch();

  const handleNextArtistClick = (e) => {
    e.preventDefault();
    if (showId < spotifyArtistIds.length-1) {
      dispatch(setShowId(showId + 1))
    }
    else {
      dispatch(setShowId(0))
    }
  }

  const handleOpenArtistAdd = (e) => {
    e.preventDefault()
    setOpenAddArtist(true)
  }

  const handleRemoveArtistClick = (e) => {
    e.preventDefault()
    setOpenRemove(true)
  }
  
  return (
    <Box sx={{display: "flex"}}>
      <Box 
        sx={{ 
          backgroundColor: "black", 
          borderRadius: "20px", 
          padding: "10px", 
          display: "flex", 
          gap: 2, 
          outline: outlineGray, 
          transition: ".3s all linear"
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "space-around"}}>
          <img 
            src={artistInfo?.filter(info => info?.id === spotifyArtistIds?.[showId])[0]?.images?.[2]?.url} 
            height="20px" 
            width="20px"
            alt="profile"
            style={{borderRadius: "50%"}}
          />
        </Box>
        <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "space-around"}}>
          <IconButton 
            style={{ border: "none", cursor: "pointer", backgroundColor: "unset", color: "white", height: "20px", width: "20px"}}
            onClick={e => {handleRemoveArtistClick(e)}}
          >
            <Tooltip arrow title="Remove artist">
              <HighlightOffRoundedIcon />
            </Tooltip>
          </IconButton>

        </Box>
        <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "space-around"}}>
          {artistInfo?.filter(info => info?.id === spotifyArtistIds?.[showId])[0]?.name}
        </Box>
        <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "space-around"}}>
          {spotifyArtistIds.length > 1 && (
            <IconButton 
              style={{ border: "none", cursor: "pointer", backgroundColor: "unset", color: "white", height: "20px", width: "20px"}}
              onClick={e => {handleNextArtistClick(e)}}
            >
              <Tooltip arrow title="Next artist">
                <NavigateNextRoundedIcon />
              </Tooltip>
            </IconButton>
          )}
          
        </Box>
      </Box>
      <Box>
      <IconButton 
        style={{ border: "none", cursor: "pointer", backgroundColor: "unset", color: "white", height: "40px", width: "40px"}}
        onClick={e => {handleOpenArtistAdd(e)}}
      >
        <Tooltip arrow title="Add new artist">
          <AddCircleOutlineRoundedIcon />
        </Tooltip>
        
      </IconButton>
      </Box>
      <AdjustedModal 
        component={<RequestSpotifyArtist />}
        open={openAddArtist}
        setOpen={setOpenAddArtist}
      />
      <TinyModal 
       component={<RemoveCheck setOpen={setOpenRemove}/>}
       open={openRemove}
       setOpen={setOpenRemove}
      />
    </Box>)
}