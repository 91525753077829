import React from "react";
import { PopularityPieChart } from "../charts/PopularityPieChart";
import { Box, Tooltip } from "@mui/material";
import { outlineGray } from "../../../pages/styleconstants";

export const PopScorePieChart = ({getThisArtistInfo}) => {
  return (
    <Box sx={{ position: "relative", padding: "20px", backgroundColor: "black", borderRadius: "30px", outline: outlineGray}}>
      <Box sx={{ position: "absolute", top: "20", left: "20"}}>
        <Tooltip arrow title="What is algorithmic popularity?">
          <a 
            href="https://medium.com/protonradio/introducing-spotify-popularity-scores-know-which-artists-can-help-you-grow-879cbf78fb98#:~:text=You%27re%20likely%20already%20familiar,temperature”%20in%20the%20almighty%20algorithm." 
            target="_blank" 
            rel="noreferrer"
          >
            <h3 style={{margin: "0px", padding: "0px", color: "white"}}>Algorithmic Popularity</h3>
          </a>
        </Tooltip>
      </Box>
      <Box sx={{ display: "flex", gap: 0, position: "absolute", top: "50%", left: "50%", transform: "translate(-50%,-50%)", marginTop: "20px"}}>
        <h2 style={{padding: "0px", margin: "0px", fontSize: "44px"}}>{getThisArtistInfo()?.popularity}</h2>
        <Box sx={{display: "flex", flexDirection: "column", justifyContent: "flex-end"}}>
          <p style={{padding: "0px", margin: "0px"}}>/100</p>
        </Box>
      </Box>
      <Box sx={{ position: "relative", width: "100%", display: "flex", justifyContent: "space-around", marginTop: "55px"}} >
        {/** Block clicking on the chart */}
        <Box sx={{ position: "absolute", width: "100%", height: "100%", top: 0, left: 0, zIndex: 1}} />
        <PopularityPieChart popScore={getThisArtistInfo()?.popularity} />
      </Box>
    </Box>)
}