import { Box } from "@mui/material";
import React from "react";

export const PopularityLineChart = ({popScore}) => {
  return (
    <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%"}}>
      <Box sx={{ width: `${popScore}%`, height: "4px", borderRadius: "2px", backgroundColor: "lightgreen"}} />
      <Box sx={{ width: `calc(98% - ${popScore}%)`, height: "4px", borderRadius: "2px", backgroundColor: "lightblue"}} />
    </Box>)
    
}