import React from "react"
// images
import trustpilotLogo from "../../recourses/images/Trustpilot.png"
import { FooterDots } from "./FooterDots";

export const PageFooter = () => {

  //trustpilotscript
  const script = document.createElement("script");
  script.src = "//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js";
  script.async = true;
  document.body.appendChild(script);

  return (
    <div className="footerContainer">
      <div
      className="leftFooter"
      style={{
        display: "flex",
        gap: "20px",
        alignItems: "center"
      }}>
        <div 
        className="footerDate"
        style={{
            zIndex: "1",
            display: "flex",
            color: "white",
          }}>
            <div>© Scan Records / {new Date().getFullYear()}</div>
        </div>
        <div className="footerDate">
          <a href="https://au.trustpilot.com/review/scanrecs.com" target="_blank" rel="noreferrer">
            <img src={trustpilotLogo} width="150px" height="100px" alt="link to trustpilot for Scan Records" />
          </a>
        </div>
      </div>
      <div
      className="rightFooter"
      style={{
        display: "flex",
        gap: "12px",
        alignItems: "center", 
      }}>
        <div><a href="./terms">Terms</a></div>
        <FooterDots />
        <div><a href="./privacy">Privacy</a></div>
      </div>
      
    </div>
  );
}