import axios from 'axios';

class ScanrecsApi {
  getFirebaseToken = async(auth0Token) => {
    const responseToken = await axios.get(
      `${process.env.REACT_APP_SERVER_URL}/firebase/auth/token/`,
      { headers: { Authorization: `Bearer ${auth0Token}` } }
    )
    return responseToken;
  }
}

export default ScanrecsApi;