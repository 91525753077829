import "./page.css"
import React from 'react';
import { VideoHeader } from '../components/allPages/VideoHeader';
import { pageColor } from "./styleconstants";
import { PageFooter } from "../components/allPages/PageFooter";
import { NavBar } from "../components/allPages/NavBar";

function NotFound() {
  return (
    <div className="page" style={{ backgroundColor: pageColor}}>
      <VideoHeader pageColor={pageColor} />
      <NavBar />
      <div className="headerTitle">
        <h1 className="headerText">Page not found</h1>
      </div>
      <div className='contentContainer'>
        <h3>This page does not exist...</h3>
        <p>Maybe you can try using our navigation bar to go to the page that you are looking for. Alternatively 
          you can also go <a href="./">back to the homepage.</a>
        </p>
        <div>
          <PageFooter />
        </div>
      </div>
    </div>
  );
}

export default NotFound;