import { configureStore } from '@reduxjs/toolkit'
import memberReducer from "./memberState/slice"
import storageSession from 'redux-persist/lib/storage/session'
import { persistReducer, persistStore } from 'redux-persist';

const persistConfig = {
  key: "member",
  storage: storageSession,
}

const persistedReducer = persistReducer(persistConfig, memberReducer)

export const store = configureStore({
  reducer: {
    member: persistedReducer,
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
})

export const persistor = persistStore(store)