import { Box, Button } from "@mui/material";
import React from "react";
import { useSelector, useDispatch } from 'react-redux'
import { LoginButton } from "./LoginButton";
import { LogoutButton } from "./LogoutButton";
import { useAuth0 } from "@auth0/auth0-react";
import { setIsArtistMode } from "../../store/memberState/slice";
import { DASHBOARD_VERSION } from "../../utils/constants";

export const HomePageContent = () => {
  const { isAuthenticated, isLoading } = useAuth0();
  const isArtistMode = useSelector(state => state.member.isArtistMode)
  const dispatch = useDispatch()

  const handleDashboardClick = (e) => {
    e.preventDefault()
    // TODO: remove Test Alert
    alert(`This is a very early preview version of the Artist Dashboard with limited features! Please report any bugs you find to felix@scanrecs.com with screenshots. \n \n If you have any questions or suggestions also contact felix@scanrecs.com. \n \n-> Version: ${DASHBOARD_VERSION}`)
    window.open("./protected", "_self")
  }

  const renderLoginSection = () => {
    if (isLoading) {
      return (<p style={{marginBottom: "9px"}}><i>Loading...</i></p>)
    }
    return (
      <Box sx={{display: "flex", gap: 1 }}>
        {isAuthenticated && (
          <>
            <LogoutButton />
            <Button variant="contained" color="inherit" onClick={handleDashboardClick} sx={{ marginLeft: "4px"}}>
              Dashboard
            </Button>
          </>
        )}
        {!isAuthenticated && <LoginButton />}
      </Box>);
  }

  return (<div className="content">
  <h1>Scan Records</h1>
  <Box sx={{ display: "flex", gap: 2, marginBottom: "30px" }}>
    <p style={{ margin: "0"}}><i><b>Record & Sample Label</b> - est. 2021 </i> </p>
    <Box sx={{ display: "flex", justifyContent: "center", flexDirection: "column"}}>
      {isArtistMode && (
        <Button sx={{ color: "white", padding: "0", height: "31px", marginLeft: "4px" }} variant="" color="inherit" onClick={() => {dispatch(setIsArtistMode(false))}}>
          Exit Artist Area
        </Button>
      )}
      {!isArtistMode && (
        
        <Button sx={{ color: "white", padding: "0", height: "31.5px", marginLeft: "4px" }} variant="" color="inherit" onClick={() => {dispatch(setIsArtistMode(true))}}>
          Enter Artist Area
        </Button>
      )}
    </Box>
    
  </Box>
  
  {isArtistMode && renderLoginSection()}
</div>)
}