import { createSlice } from '@reduxjs/toolkit'
import { getArtistInformation } from './actions'

const initialState = {
  spotifyArtistIds: [],
  artistInfo: [],
  showId: 0,
  isArtistMode: false,
  spotifyApiLoading: false,
  removingArtistLoading: false,
  criticalError: "",
  pageID: "spotify", // ENUM spotify; soundcloud; ...
}

const memberSlice = createSlice({
  name: 'member',
  initialState,
  reducers: {
    setIsArtistMode: (state, action) => {
      state.isArtistMode = action.payload
    },
    setSpotifyArtistIds: (state, action) => {
      state.spotifyArtistIds = action.payload
    },
    setArtistInfo: (state, action) => {
      state.artistInfo = action.payload
    },
    setShowId: (state, action) => {
      state.showId = action.payload
    },
    setSpotifyApiLoading: (state, action) => {
      state.spotifyApiLoading = action.payload
    },
    setCriticalError: (state, action) => {
      state.criticalError = action.payload
    },
    setRemovingArtistLoading: (state, action) => {
      state.removingArtistLoading = action.payload
    },
    setPageID: (state, action) => {
      state.pageID = action.payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getArtistInformation.fulfilled(), (state, action) => {
        state.artistInfo = action.payload;
        state.spotifyApiLoading = false;
      })
      .addCase(getArtistInformation.rejected(), (state, action) => {
        state.spotifyApiLoading = false;
        state.criticalError = "Spotify API call failed, please try again later"
      })
      .addCase(getArtistInformation.pending(), (state, action) => {
        state.spotifyApiLoading = true;
      })
      // You can chain calls, or have separate `builder.addCase()` lines each time
      // .addCase(decrement, (state, action) => {})
      // You can match a range of action types
  }
})

export const { 
  setIsArtistMode, 
  setSpotifyArtistIds, 
  setArtistInfo, 
  setShowId, 
  setSpotifyApiLoading,
  setCriticalError,
  setRemovingArtistLoading,
  setPageID
} = memberSlice.actions
export default memberSlice.reducer